
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { Navigate } from "react-router-dom";
import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './css/font/SpoqaHanSansNeo.css'
import './App.css';
//import './css/bootstrap_5.1.3.css'
import 'bootstrap/dist/css/bootstrap.css';
import './css/common.css';
import MainLayout from "./common/Main_layout.js";
import BannerIndex from './common/banner_index.js';
import Subscribe from './pages/subscribe';
import Admin_Login from "./pages/admin/login.js";
import Dashboard from "./pages/admin/dashboard";
import Privacy from "./pages/privacy";
import Mypage_Dashboard from "./pages/mypage/dashboard";
//import ApiLog from "./pages/elements/mypage/apiLog";
import Agreement from "./pages/agreement";
//import ApiDashboard from "./pages/mypage/apiDashboard";
import Register_intro from "./pages/register/register_intro";
import Register_form from "./pages/register/register_form";
import ModifyInfo from "./pages/mypage/modifyInfo";
//import WebAPI from "./pages/elements/mypage/webapi/webapi";
//import Order_view from "./pages/mypage/order_view";
import Order from "./pages/order/order";
import FindID from "./pages/register/findid";
import FindPW from "./pages/register/findpw";
//import CouponList_user from "./pages/elements/mypage/couponlist";
//import Orderlist_user from "./pages/elements/mypage/orderlist";
//import Receipt_Form from "./pages/elements/mypage/modules/receipt_form";
import Docs_Intro from "./pages/introduction/docs_intro.js";
import Board from "./pages/board/Board.js";
import Board_view from "./pages/board/Board_view.js";
import CustomerService from "./pages/cs/cs.js";
import ModelInfo from "./pages/modelinfo/modelinfo.js";
import Qna from "./pages/mypage/qna/qna.js";
import QnaList from "./pages/mypage/qna/qnaList.js";
import Qna_view from "./pages/mypage/qna/qna_view.js";
import GenuineList from "./pages/mypage/genuineList.js";
import CSBoard from "./pages/cs/cs_board.js";

function App() {



  return (
     <div className="App">
      <Routes>
        <Route path="/" exact element={<Navigate to ="/index"/>}/>
        <Route path="/mypage" exact element={<Navigate to ="/mypage/dashboard"/>}/>
        <Route element={<MainLayout/>}>
          <Route path="/index" exact element={<BannerIndex/>}/>
          <Route path="/findid" exact element={<FindID />}/>
          <Route path="/findpw" exact element={<FindPW />}/>
          <Route path="/policy/privacy" exact element={<Privacy/>}/>
          <Route path="/policy/agreement" exact element={<Agreement/>}/>
          <Route path="/order" exact element={<Order/>}/>
          <Route path="/subscribe" exact element={<Subscribe />}/>
          <Route path="/board/news" exact element={<Board page="news" type="list"/>}/>
          <Route path="/board/promotion" exact element={<Board page="promotion" type="list"/>}/>
          <Route path="/board/articles" exact element={<Board page="articles" type="list"/>}/>
          <Route path="/mypage/dashboard" exact element={<Mypage_Dashboard />}/>
          <Route path="/mypage/qnaList" exact element={<QnaList />}/>
          <Route path="/mypage/qna" exact element={<Qna />}/>
          <Route path="/mypage/qnaView/:uid" exact element={<Qna_view />}/>
          <Route path="/mypage/modifyInfo" exact element={<ModifyInfo />}/>
          <Route path="/mypage/genuineList" exact element={<GenuineList />}/>
          <Route path="/register/intro" exact element={<Register_intro/>}/>
          <Route path="/register/goRegister" exact element={<Register_form/>}/>
          <Route path="/intro" exact element={<Docs_Intro page="greeting"/>}/>
          <Route path="/board/news/articleView/:uid" exact element={<Board page="news" type="view"/>} />
          <Route path="/board/promotion/articleView/:uid" exact element={<Board page="promotion" type="view"/>} />
          <Route path="/board/articles/articleView/:uid" exact element={<Board page="articles" type="view"/>} />
          <Route path="/cs/solution" exact element={<CustomerService page="solution"/>}/>
          <Route path="/cs/solution/articleView/:uid" exact element={<CustomerService page="solution_view" type="view"/>} />
          <Route path="/cs/warrenty" exact element={<CustomerService page="warrenty"/>}/>
          <Route path="/cs/agreement" exact element={<CustomerService page="agreement"/>}/>
          <Route path="/cs/cost" exact element={<CustomerService page="cost"/>}/>
          <Route path="/cs/genuine" exact element={<CustomerService page="genuine"/>}/>
          <Route path="/cs/download" exact element={<CustomerService page="download"/>}/>
          <Route path="/cs/modelinfo/:uid" exact element={<ModelInfo/>}/>
        </Route>
        
        <Route path="/admin/login/" exact element={<Admin_Login/>}/>
        <Route path="/admin/dashboard" element={<Dashboard/>}/>
        <Route path="/admin/ArticleList" element={<Dashboard menu="ArticleList"/>}/>
        <Route path="/admin/registerArticle" element={<Dashboard menu="registerArticle"/>}/>
        <Route path="/admin/ModelList" element={<Dashboard menu="ModelList"/>}/>
        <Route path="/admin/ModelCategoryList" element={<Dashboard menu="ModelCategoryList"/>}/>
        <Route path="/admin/ModifyServiceType" element={<Dashboard menu="modifyservicetype"/>}/>
        <Route path="/admin/UserList" element={<Dashboard menu="userlist"/>}/> 
        <Route path="/admin/ProductList" element={<Dashboard menu="ProductList"/>}/> 
        <Route path="/admin/VendorList" element={<Dashboard menu="VendorList"/>}/>
        <Route path="/admin/RegisterVendor" element={<Dashboard menu="RegisterVendor"/>}/> 
        <Route path="/admin/EquipmentList" element={<Dashboard menu="EquipmentList"/>}/> 
        <Route path="/admin/SoftwareCategoryList" element={<Dashboard menu="SoftwareCategoryList"/>}/> 
        <Route path="/admin/registerPayplan" element={<Dashboard menu="registerpayplan"/>}/>
        <Route path="/admin/registerModel" element={<Dashboard menu="registerModel"/>}/>
        <Route path="/admin/registerProduct" element={<Dashboard menu="registerProduct"/>}/>
        <Route path="/admin/modifyOrder" element={<Dashboard menu="modifyorder"/>}/>
        <Route path="/admin/SoftwareOSList" element={<Dashboard menu="SoftwareOSList"/>}/>
        <Route path="/admin/GenuineList" element={<Dashboard menu="GenuineList"/>}/>
        <Route path="/admin/registerCoupon" element={<Dashboard menu="registercoupon"/>}/>
        <Route path="/admin/modifyCoupon" element={<Dashboard menu="modifycoupon"/>}/>
        <Route path="/admin/RegisterEquipment" element={<Dashboard menu="RegisterEquipment"/>}/>
        <Route path="/admin/visitorLog" element={<Dashboard menu="visitorlog"/>}/> 
        <Route path="/admin/QnaList" element={<Dashboard menu="QnaList"/>}/>
        <Route path="/admin/registerQna" element={<Dashboard menu="registerQna"/>}/>
        <Route path="/admin/ASList" element={<Dashboard menu="ASList"/>}/>
      </Routes>
      <a id="chat-channel-button" style={{position:"fixed", right:"0", bottom:"-1px"}} href="javascript:chatChannel()">
		    <img src="/assets/img/consult_small_yellow_pc.png" alt="카카오톡 채널 채팅하기 버튼" />
	    </a>
      </div>


    /*<div className="App">

    <Routes>
        <Route path="/admin/*" exact>
          <Route path="/admin/login/" exact element={<Admin_Login/>}/>
        </Route>
      </Routes>


      <Header />
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
  </header>
    </div>*/
  );
}

export default App;
