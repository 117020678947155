import React from "react";
import {Link, useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css'
import Dropdown from './Dropdown.js';
import '../css/header.css'
import LoginModal from "./Login_modal.js";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import { useCookies } from "react-cookie";

function Header_index(props){
    const navigate = useNavigate();
    
    const [dropdownVisibility, setDropdownVisibility] = useState(false);
    const [featuresdropdownVisibility, setFeaturesDropdownVisibility] = useState(false);
    const [supportdropdownVisibility, setSupportDropdownVisibility] = useState(false);
    const [docsdropdownVisibility, setDocsDropdownVisibility] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(["topBannerShow"]);

    const scrollToTop = ()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const closeTopBanner = ()=>{
        setCookie("topBannerShow", true, {maxAge: 86400});
        props.setTopBannerShow(false);

    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          준비중입니다.. 조금만 기다려 주세요!
        </Tooltip>
      );

    useEffect(()=>{
        if (cookies.topBannerShow ==undefined){
            //props.setTopBannerShow(true);
        }else{
            props.setTopBannerShow(false);
        }

    }, []);
    useEffect(()=>{
        //console.log("expanded : "+expanded);
        const mobilebtn = document.querySelector('#mobile-btn');
        if((window.getComputedStyle(mobilebtn).display != "none" && expanded==true)){
            menu_colored();
        }else{
            //menu_transparent();
        }
        
        document.addEventListener('scroll', scroll_control);
    }, [expanded])
    const scroll_control = ()=>{
        const menu = document.querySelector('#mainNav');
        const videobanner = document.querySelector('#video-banner');
        if(videobanner == null)
            return;
        const videobannerHeight = videobanner.offsetHeight;
        const logo = document.querySelector('#logo');
        const topmenu = document.querySelectorAll('.top-menu');
        const accbtn = document.querySelector('#accbtn');
        const mobilebtn = document.querySelector('#mobile-btn');
        const searchbtn = document.querySelector('#searchbtn');
        const logout_img = document.querySelector("#logout_img");
        //console.log(expanded)
        if (window.scrollY > videobannerHeight || (window.getComputedStyle(mobilebtn).display != "none" && expanded==true)) {
            menu.classList.add('shadow-sm');
            menu.classList.add('bg-light');
            accbtn.classList.remove('btn-outline-light');
            accbtn.classList.add('btn-outline-primary');
            mobilebtn.classList.remove('text-white');
            mobilebtn.classList.remove('border-white');
            searchbtn.classList.remove('btn-outline-light');
            searchbtn.classList.add('btn-outline-primary');
            if(logout_img != null)
                logout_img.classList.remove('invert-color');
            for(var i=0; i<topmenu.length; i++){
                const item = topmenu.item(i);
                item.classList.remove('text-light');
            }
            logo.src = "/assets/img/logo_footer.png";
          } else { //transparent
            menu.classList.remove('shadow-sm');
            menu.classList.remove('bg-light');
            accbtn.classList.add('btn-outline-light');
            accbtn.classList.remove('btn-outline-primary');
            mobilebtn.classList.add('text-white');
            mobilebtn.classList.add('border-white');
            searchbtn.classList.add('btn-outline-light');
            searchbtn.classList.remove('btn-outline-primary');
            if(logout_img != null)
                logout_img.classList.add('invert-color');
            for(var i=0; i<topmenu.length; i++){
                const item = topmenu.item(i);
                item.classList.add('text-light');
            }
            logo.src = "/assets/img/logo.png";
          }
    }
    const menu_colored = ()=>{
        const menu = document.querySelector('#mainNav');
        const mobilebtn = document.querySelector('#mobile-btn');
        const searchbtn = document.querySelector('#searchbtn');
        const topmenu = document.querySelectorAll('.top-menu');
        const accbtn = document.querySelector('#accbtn');
        const logo = document.querySelector('#logo');
        const logout_img = document.querySelector("#logout_img");
        menu.classList.add('shadow-sm');
        menu.classList.add('bg-light');
        accbtn.classList.remove('btn-outline-light');
        accbtn.classList.add('btn-outline-primary');
        mobilebtn.classList.remove('text-white');
        mobilebtn.classList.remove('border-white');
        searchbtn.classList.remove('btn-outline-light');
        searchbtn.classList.add('btn-outline-primary');
        if(logout_img != null)
            logout_img.classList.remove('invert-color');
        for(var i=0; i<topmenu.length; i++){
            const item = topmenu.item(i);
            item.classList.remove('text-light');
        }
        logo.src = "/assets/img/logo_footer.png";
    }
    const menu_transparent = ()=>{
        const menu = document.querySelector('#mainNav');
        const mobilebtn = document.querySelector('#mobile-btn');
        const searchbtn = document.querySelector('#searchbtn');
        const topmenu = document.querySelectorAll('.top-menu');
        const accbtn = document.querySelector('#accbtn');
        const logo = document.querySelector('#logo');
        const logout_img = document.querySelector("#logout_img");
        menu.classList.remove('shadow-sm');
        menu.classList.remove('bg-light');
        accbtn.classList.remove('btn-outline-light');
        accbtn.classList.add('btn-outline-primary');
        mobilebtn.classList.add('text-white');
        mobilebtn.classList.add('border-white');
        searchbtn.classList.add('btn-outline-light');
        searchbtn.classList.remove('btn-outline-primary');
        if(logout_img != null)
            logout_img.classList.add('invert-color');
        for(var i=0; i<topmenu.length; i++){
            const item = topmenu.item(i);
            item.classList.add('text-light');
        }
        logo.src = "/assets/img/logo.png";
        
        
    }
    const menuControl = ()=>{
        //const menu = document.querySelector('#mainNav');
        //const videobanner = document.querySelector('#video-banner');
        document.addEventListener('scroll', scroll_control);
    }
    useEffect(()=>{
        menuControl();
        return()=>{
            document.removeEventListener('scroll', scroll_control);
        }
    }, [])
    return (
        <>
            {
                props.topBannerShow == true ? 
                <div className="bg-danger fixed-top" style={{height:"50px"}}>
                    <Container className="text-center text-white" style={{height:"100%", lineHeight:"50px", cursor:"pointer"}} >
                        <span className=" fw-bold " onClick={()=>{navigate('register/intro')}}>
                            회원가입만 해도 누끼, 음성합성 AI 크레딧 지급!
                        </span>
                        <span className="position-absolute" style={{right:"0"}}>
                            <span className="d-inline-flex">
                                <span className="d-none d-lg-block" style={{fontSize:"0.8rem"}}>오늘 하루 보지 않기</span>
                                <Button variant="btn-sm white" onClick={()=>{closeTopBanner();}}>
                                    <i className="bi bi-x-lg"></i>
                                </Button>
                            </span>
                        </span>
                    </Container>
                </div>
                :""
            }
            <Navbar expanded={expanded} expand="lg" bg="" fixed="top" className="" id="mainNav" style={{marginTop:props.topBannerShow==true ? "50px" : ""}}>
                <Container className="px-4 px-lg-5">
                    <Link className="navbar-brand fw-bold m-auto" to="/index" onClick={()=>{scrollToTop(); }}><img style={{maxWidth:"200px"}} id="logo" src={process.env.PUBLIC_URL + '/assets/img/logo.png'} /></Link>
                    <Navbar.Toggle type="button" className="text-white border-white" id="mobile-btn" onClick={()=>{setExpanded(!expanded);}} aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="bi-list"></i>
                    </Navbar.Toggle>
                    <Navbar.Collapse onTransitionEnd={()=>{const mobilebtn = document.querySelector('#mobile-btn'); if(window.getComputedStyle(mobilebtn).display != "none" && expanded==false){menu_transparent();}}} id="navbarResponsive">
                        <Nav className=" ms-auto me-4 my-3 my-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link top-menu me-lg-3 text-light" to="/intro">회사소개</Link>
                            </li>
                            <li className="nav-item"  onMouseOver={e => {setDropdownVisibility(true); setFeaturesDropdownVisibility();  setDocsDropdownVisibility(false); }} onMouseOut={e=> setDropdownVisibility(false)}>
                                <a className="nav-link top-menu me-lg-3 text-light" href="#">나미소식</a>
                                <Dropdown visibility={dropdownVisibility}>
                                    <ul id="dropdown-demo" className="list-group">
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/board/news" onClick={()=>{setDropdownVisibility(false); scrollToTop(); setExpanded(false)}} >최신소식</Link></li>
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/board/promotion" onClick={()=>{setDropdownVisibility(false); scrollToTop(); setExpanded(false)}}  >홍보자료</Link></li>
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/board/articles" onClick={()=>{setDropdownVisibility(false); scrollToTop(); setExpanded(false)}}  >관련기사</Link></li>
                                    </ul>
                                </Dropdown>
                            </li>
                            <li className="nav-item"  onMouseOver={e => {setSupportDropdownVisibility(true); setFeaturesDropdownVisibility();  setDropdownVisibility(false);}} onMouseOut={e=> setSupportDropdownVisibility(false)}>
                                <a className="nav-link top-menu me-lg-3 text-light" href="#">고객지원</a>
                                <Dropdown visibility={supportdropdownVisibility}>
                                    <ul id="dropdown-demo" className="list-group">
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/cs/solution" onClick={()=>{setDocsDropdownVisibility(false); scrollToTop(); setExpanded(false)}} >스스로 해결</Link></li>
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/cs/warrenty" onClick={()=>{setDocsDropdownVisibility(false); scrollToTop(); setExpanded(false)}} >보증규정</Link></li>
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/cs/agreement" onClick={()=>{setDocsDropdownVisibility(false); scrollToTop(); setExpanded(false)}}  >이용약관</Link></li>
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/cs/cost" onClick={()=>{setDocsDropdownVisibility(false); scrollToTop(); setExpanded(false)}}  >점검/수리비 안내</Link></li>
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/cs/genuine" onClick={()=>{setDocsDropdownVisibility(false); scrollToTop(); setExpanded(false)}}  >정품등록</Link></li>
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="/cs/download" onClick={()=>{setDocsDropdownVisibility(false); scrollToTop(); setExpanded(false)}}  >다운로드센터</Link></li>
                                    </ul>
                                </Dropdown>
                            </li>
                            
                            <li className="nav-item"  onMouseOver={e => {setDocsDropdownVisibility(true); setFeaturesDropdownVisibility();  setDropdownVisibility(false);}} onMouseOut={e=> setDocsDropdownVisibility(false)}>
                                <a className="nav-link top-menu me-lg-0 text-light" href="#">나미서비스</a>
                                <Dropdown visibility={docsdropdownVisibility}>
                                    <ul id="dropdown-demo" className="list-group">
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="#" onClick={()=>{setDocsDropdownVisibility(false); scrollToTop(); setExpanded(false); window.open("https://store.namisnt.com/", "_blank", "noopener", "noreferrer");}} >나미스토어-직영</Link></li>
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="#" onClick={()=>{setDocsDropdownVisibility(false); scrollToTop(); setExpanded(false); window.open("https://smartstore.naver.com/namisnt", "_blank", "noopener", "noreferrer");}}  >네이버스마트스토어</Link></li>
                                        <li className="list-group-item"><Link className="nav-link me-lg-3" to="#" onClick={()=>{setDocsDropdownVisibility(false); scrollToTop(); setExpanded(false); window.open("https://ai.namisnt.com/", "_blank", "noopener", "noreferrer");}}  >NAMISNT AI</Link></li>
                                    </ul>
                                </Dropdown>
                            </li>
                        </Nav>
                        <button className="rounded-pill px-3 mb-2 mb-lg-0 btn btn-outline-light me-3"  id="searchbtn"  onClick={()=>{props.setSearchShow(true); setExpanded(false)}}><i className="bi bi-search"></i></button>
                        {
                            !props.isLogged ? 
                            <button className="btn btn-outline-light rounded-pill px-3 mb-2 mb-lg-0" id="accbtn" onClick={()=>{props.setLoginShow(true); setExpanded(false)}}>
                                <span className="d-flex align-items-center">
                                    <span className="small">로그인</span>
                                </span>
                            </button>
                            :
                            <>
                                <button className="btn btn-outline-light rounded-pill px-3 mb-2 mb-lg-0" id="accbtn" onClick={()=>{navigate("/mypage/dashboard");  setExpanded(false)}}>
                                    <span className="d-flex align-items-center">
                                        <span className="small">마이페이지</span>
                                    </span>
                                </button>
                                <Button variant="transparent" type="button" size="sm" className="ms-3" onClick={()=>{props.Logout_func();  setExpanded(false)}}>
                                    <img style={{height:"1.5rem"}} className="invert-color" id="logout_img" src={process.env.PUBLIC_URL + '/assets/img/logout.png'}></img>
                                </Button>
                            </>
                        }
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}



export default Header_index;