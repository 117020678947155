import React from "react";
import { useState, useEffect } from "react";
import {Row, Col, Container, ListGroup, Card, Table, Navbar, Nav, Button } from "react-bootstrap";
import Loading from "../../common/Loading";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";


function Board_view(props){
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({article_info:{uid:0, title:"", content:"", is_notice:0, created_date:0, attachments:[]}, writer_info:{nickname:"", id:"", level:0}});
    const requestData = () =>{
        setLoading(true);
        axios.get('/rest/getBoardArticle', 
            {params:
            {
                uid:props.uid
            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                let data = {article_info:response.data.data.article_info, writer_info:response.data.data.writer_info};
                props.setPage(response.data.data.article_info.board_code);
                setData(data);
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    useEffect(()=>{requestData();}, []);
    
    return(
        <>
            <div className="px-lg-5 mx-lg-5 pb-5">
                <div className="no-scrollbar">
                    <div className="w-100">
                        {loading == true ? <Loading/> : ""}
                        <div className="py-3 px-0 px-lg-2" style={{borderTop:"3px solid #585858", borderBottom:"1px solid #D8D8D8"}}>
                            <div className="h5">
                                {data.article_info.title}
                            </div>
                            <div>
                                <span>{data.writer_info.nickname}</span> <span>{data.article_info.created_date}</span>
                            </div>
                        </div>
                            {data.article_info.attachments != undefined && data.article_info.attachments.length > 0 ?
                                <div className="mt-3 ms-lg-3">
                                    {data.article_info.attachments.map(function(a,i){
                                        return(
                                            <Row key={i}>
                                                <div>

                                                    <span className="me-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-3 bi bi-floppy-fill" viewBox="0 0 16 16">
                                                            <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z"/>
                                                            <path d="M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z"/>
                                                        </svg>
                                                        <Link to={"/rest/downloadImage?filename="+a.fileloc} target="_blank">{a.filename}</Link>
                                                    </span>
                                                </div>
                                            </Row>
                                        );
                                    })}
                                </div>
                                :
                                ""
                            }

                        <div className="p-0 p-lg-3 board-view"  style={{borderBottom:"1px solid #D8D8D8"}} dangerouslySetInnerHTML={{__html:data.article_info.content}}>
                        </div>
                    </div>
                </div>
                <div className="py-3">
                    <Button className="float-end" variant="light" onClick={()=>{navigate('/board/'+data.article_info.board_code)}}>목록</Button>
                </div>
            </div> 
        </>
    );
}

export default Board_view;