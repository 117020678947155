import React, { useEffect, useState } from "react";
import Banner from "../../common/banner";
import { Breadcrumb } from "react-bootstrap";
import Board_General from "../board/Board_General";
import Board_view from "../board/Board_view";
import { useParams } from "react-router-dom";
function CSBoard(props){
    let params = useParams();
    const [page, setPage] = useState(props.page);
    useEffect(()=>{
        
    }, [page]);
    useEffect(()=>{setPage(props.page)},[props])
    return(
        <>
            <div className="pt-0 sections">
                <section className="sections-white mt-0 p-0 pt-lg-4 pt-4">
                    <div className="section-content px-0">
                        {
                            props.type == "list" ? 
                                <>
                                { page == "solution"?
                                    <Board_General board_code={page}/>
                                    :
                                    ""
                                }
                                </>
                            :
                            props.type == "view" ? 
                                <Board_view uid={params.uid} setPage={setPage}/>
                                :
                                ""
                        }
                    </div>
                </section>
            </div>
        </>
    );
}

export default CSBoard;